import styled from "styled-components"

const ResponsiveWrapper = styled.div`
    display: flex;
    flex-direction: row;
    ${({verticalAlign}) => verticalAlign? `align-items: ${verticalAlign};` : ""}
    ${({horizontalAlign}) => horizontalAlign? `justify-content: ${horizontalAlign};` : ""}

    @media (max-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.mobile}) {
        display: flex;
        flex-direction: column;    
        align-items: center;
    }

`

export default ResponsiveWrapper