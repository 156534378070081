import React, { useState, useRef } from "react"
import {Link} from "gatsby"
import SiteLayout from "../components/sitelayout"
import Header from "../components/header"
import SEO from "../components/seo"
import Button, {OverlapButtonWrapper} from "../components/button"
import Container from "../components/container"
import TextInput from "../components/textinput"
import ResponsiveWrapper from "../components/responsivewrapper"
import ErrorMessage from "../components/errormessage"
import TextDiv from "../components/textdiv"
import Ellipsis from "../components/ellipsis"

import {setInputState} from "../services/helper"
import {callUnauthenticatedApi} from "../services/api"
import { handleEventError } from "../services/error"
import {useAuth} from "../services/auth"
import {useTheme} from "../services/theme"

const HomePage = ({location}) => {
  const user = useAuth().getUser()
  const form = useRef()
  const theme = useTheme()

  const [email, setEmail] = useState(user ? user.email : '')
  const [validationError, setValidationError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [waitlisted, setWaitlisted] = useState(false)

  const handleSubmit = async (e) => {
    try {
        e.preventDefault()

      if(!form.current.checkValidity()) {
        setErrorMessage('Please enter a valid email.')
        setValidationError(true)
        return
      }

      setSubmitting(true)
      await callUnauthenticatedApi({
        method: 'post',
        url:'/entries',
        data: {
            collectionId: process.env.GATSBY_WAITLIST_COLLECTION_ID,
            submissionData: {
                email: email
            },
            //submitterId: location.origin,
            //lookupId: email
        }
      })

      setWaitlisted(true)

    } catch (err) {
      setErrorMessage("Oops! Something's not right. Please try again or reach out to customer support.")
      setValidationError(true)
      handleEventError(err, user, false)
    } finally {
      setSubmitting(false)
    }

  }

  return (
    <SiteLayout
      signup={
        <Link to="/tools/hubspot-webflow-form-tool"><Button secondary="true">Get free tools</Button></Link>
      }
    >
      <SEO title="Build advanced website experiences without writing code"/>
      <Container>
        <Header
          paddingTop="20vh"
          title="Build advanced website experiences without writing code."
          subTitle="The no-code toolkit to use powerful APIs on your website, deliver interactive user experiences and grow your income."
          align="left"
          maxWidth="720px"
        />
        {
          !waitlisted && 
          <form ref={form} noValidate>
            <ResponsiveWrapper horizontalAlign="flex-start">
              <TextInput 
                placeholder="Your email" 
                type="email" minWidth="250px" maxWidth="300px" 
                required={true}
                borderRadius="25px"
                value={email} 
                onChange={e => setInputState(e.target.value, setEmail, false, setValidationError)}
                returnHandler={handleSubmit}
                error={validationError ? true : false}
                focus={true}
                backgroundColor="transparent"
                borderColor={theme.colors.primary}
                maxHeight="40px"
              />
              <OverlapButtonWrapper>
                <Button 
                  breakpoint={theme.breakpoints.mobile} 
                  responsiveStyle="min-width: 300px; margin-top: 10px;" 
                  noTransform="true" 
                  onClick={handleSubmit}
                  disabled={submitting}
                  marginTop="0px"
                > 
                  {!submitting ? `Request Early Access` : <Ellipsis/>}
                </Button>
              </OverlapButtonWrapper>
            </ResponsiveWrapper>
            <ErrorMessage paddingTop="15px" breakpoint={theme.breakpoints.mobile} responsiveAlign="center">
              {validationError? errorMessage : ""}
            </ErrorMessage>
          </form>
        }
        {
          waitlisted && 
          <TextDiv maxWidth="540px" breakpoint={theme.breakpoints.mobile} responsiveAlign="center">
            Thanks! We're reviewing your request and will be in touch via email.
          </TextDiv>  
        }
      </Container>
    </SiteLayout>    
  )
}

export default HomePage
