import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { handleEventError } from "../services/error"

const StyledInput = styled.input`
    border-style: none;
    outline: none;
    font-size: 14px;
    width: 100%;
    line-height: 20px;
    padding-left: 23px;
    padding-right: 23px;
    padding-top: ${({paddingTop}) => paddingTop};
    padding-bottom: ${({paddingBottom}) => paddingBottom};
    ${({backgroundColor}) => backgroundColor ? `background-color: ${backgroundColor};`:``}
    ${({color}) => color ? `color: ${color};`:``}
      
    ::placeholder {
        color: ${({error, placeholderColor, theme}) => error ? theme.colors.error : placeholderColor || theme.colors.placeholderText };
        font-size: 14px;
    }
    @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
        font-size: 16px;
    }
`

//TODO - why is border color not changing on input focus? same issue on text area too
const StyledDiv = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
    border-radius: ${({borderRadius}) => borderRadius || "5px"};
    border-style: solid;
    border-width: 1px;
    border-color: ${({error, borderColor, theme}) => error ? theme.colors.error : borderColor || theme.colors.inputBorder};
    margin-top: ${({marginTop}) => marginTop || "0px"};
    margin-bottom: ${({marginBottom}) => marginBottom || "0px"};
    ${({marginLeft}) => marginLeft ? `margin-left: ${marginLeft};` : ""}
    ${({marginRight}) => marginRight ? `margin-right: ${marginRight};` : ""}
    padding-left: ${({paddingLeft}) => paddingLeft || "2px"};
    padding-right: ${({paddingRight}) => paddingRight || "2px"};
    padding-top: 2px;
    padding-bottom: 2px;
    min-width: ${({minWidth}) => minWidth || "340px"};
    max-width: ${({maxWidth}) => maxWidth || "540px"};
    min-height: ${({minHeight}) => minHeight || "40px"};
    max-height: ${({maxHeight}) => maxHeight || "50px"};
    background-color: ${({backgroundColor, theme}) => backgroundColor || theme.colors.background};

    ${StyledInput}:focus & {
        border-color: ${({color, theme}) => color || theme.colors.focusInputBorder};
    }
`
const Label = styled.span`
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    background-color: ${({backgroundColor, theme}) => backgroundColor || theme.colors.background};
    position: absolute;
    top: -10px;
    left: 20px;
    color: ${({error, color, theme}) => error? theme.colors.error : color || theme.colors.inputBorder};
    font-size: 12px;
`

export const getInputWithPrefix = (prefix, value) => {
    try {
        const trimmedValue = value.trim()
        const length = prefix.length
        if (length > trimmedValue.length) {
            return prefix
        } else if (prefix === trimmedValue.slice(0,length)) {
            //check if prefix is repeated
            if(prefix === trimmedValue.slice(length,length*2)){
                return trimmedValue.slice(length)
            }
            return value
        } else {
            //prefix was deleted
            return prefix + trimmedValue.slice(length-1)
            //TODO:handle case where multiple characters deleted in prefix?
        }
    } catch (err) {
        handleEventError(err, false, false)
    }

}

const TextInput = ({ returnHandler, focus, error, label, type, required, placeholder, 
    value, onChange, color, backgroundColor, borderColor, placeholderColor, ...rest }) => {
    const input = useRef()

    const handleReturn = e => {
        try {
            if(e.keyCode === 13) {
                e.preventDefault()
                returnHandler(e)
            }
        } catch (err) {
            handleEventError(err, false, false)
        } 

    }

    useEffect(() => {
        if (focus){
            input.current.focus()
        }
    },[focus])

    useEffect(() => {
        input.current.required = required
    },[required])

    return (
        <StyledDiv error={error} borderColor={borderColor} color={color} backgroundColor={backgroundColor} {...rest}>
            {
                label &&
                <Label error={error} color={borderColor} backgroundColor={backgroundColor} >{label}</Label>
            }
            <StyledInput 
                ref={input}
                onKeyUp={handleReturn}
                type={type} 
                placeholder={placeholder}
                placeholderColor={placeholderColor}
                value={value}
                onChange={onChange}
                error={error}
                color={color}
                backgroundColor={backgroundColor} 
                paddingTop={label ? "12px" : "7px"}
                paddingBottom={label ? "12px" : "7px"}
            />
        </StyledDiv>
    )
}

export default TextInput