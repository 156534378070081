import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const StyledHeader = styled.header`
  padding-top: ${({paddingTop}) => paddingTop || "20px"};
  padding-bottom: ${({paddingBottom}) => paddingBottom || "20px"};
  text-align: ${({align}) => align || "center"};
  ${({marginLeft}) => marginLeft ? `margin-left: ${marginLeft};` : ""}
  ${({marginRight}) => marginRight ? `margin-right: ${marginRight};` : ""}
  display: flex;
  flex-direction: column;
  align-items: ${({align}) => align === `left` ? `flex-start` : `center`};

  @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
    align-items: center;
    text-align: center;
  }
`

const Category = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: -15px;
  color: ${({theme}) => theme.colors.subtleText}
`
const Title = styled.h1`
  font-size: ${({fontSize}) => fontSize || "38px"};
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 10px;
  ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}

  @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    font-size: ${({fontSize}) => fontSize || "36px"};
  }

  @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
    ${({fontSize}) => fontSize || "32px"}  }
`
const SubTitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}

  @media (max-width: ${({theme}) => theme.breakpoints.tablet}) {
    font-size: 16px;
  }

  @media (max-width: ${({theme}) => theme.breakpoints.mobile}) {
    font-size: 15px;
  }
`
const Header = ({title, subTitle, titleFontSize, maxWidth, category, ...rest}) => {
  return (
    <StyledHeader {...rest}>
      { category && <Category>{category}</Category> }
      <Title maxWidth={maxWidth} fontSize={titleFontSize}>{title}</Title>
      {(subTitle && (subTitle.trim() !== "")) ? <SubTitle maxWidth={maxWidth}>{subTitle}</SubTitle> : ""}
    </StyledHeader>
  )
}

Header.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
}

export default Header
