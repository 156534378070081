import styled from "styled-components"

export default styled.div`
    padding-top: ${({paddingTop}) => paddingTop || "5px"};
    padding-bottom: ${({paddingBottom}) => paddingBottom || "5px"};
    color: ${({theme}) => theme.colors.error};
    font-size: 12px;
    line-height: 14px;
    min-height: ${({minHeight}) => minHeight || "50px"};
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    ${({align}) => align ? `text-align: ${align};` : ""}
    
    @media (max-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.mobile}) {
        ${({responsiveAlign}) => responsiveAlign ? `text-align: ${responsiveAlign};` : ""}
    }
`