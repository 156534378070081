import styled from "styled-components"

export default styled.div`
    ${({maxWidth}) => maxWidth ? `max-width: ${maxWidth};` : ""}
    ${({marginTop}) => marginTop ? `margin-top: ${marginTop};` : ""}
    ${({marginBottom}) => marginBottom ? `margin-bottom: ${marginBottom};` : ""}
    ${({align}) => align ? `text-align: ${align};` : ""}
    ${({color}) => color ? `color: ${color};` : ""}
    ${({fontSize}) => fontSize ? `font-size: ${fontSize};` : ""}
    ${({fontWeight}) => fontWeight ? `font-weight: ${fontWeight};` : ""}


    @media (max-width: ${({breakpoint, theme}) => breakpoint || theme.breakpoints.mobile}) {
        ${({responsiveAlign}) => responsiveAlign ? `text-align: ${responsiveAlign};` : ""}
    
    }

`
